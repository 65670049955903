import * as _ from 'lodash';

import {UnitTable} from '../shared/Unit';
import {BoqToCompare} from './IBoqToCompare';
import {IOptionPageRow} from '../pages/option-list/option-page.row';

// interface IAppendedByFrontend {
//   blue?: boolean;
//   dynamicmax?: number;
//   dynamicmin?: number;
//   red?: boolean;
//   unit?: string;
// }
const DEFAULT_NAME_FOR_BASE_DESIGN_INFRASTRUCTURE = '(?:Base Design Infrastructure|Base Option)';
const DEFAULT_PREFIX_FOR_BOQ_NAME = 'CALC';
const BASE_DESIGN_QUANTITY_FORMAT = new RegExp(
  `^Quantity_(${DEFAULT_PREFIX_FOR_BOQ_NAME}-\\d+?_${DEFAULT_NAME_FOR_BASE_DESIGN_INFRASTRUCTURE})$`);
const OPTION_QUANTITY_FORMAT = new RegExp(
  `^Quantity_(${DEFAULT_PREFIX_FOR_BOQ_NAME}-\\d+?_.+)$`);

interface IBoqCodeToCompare {
  actual_amt: number;
  boq_section_name: string;
  boq_code_cd: string;
  metric_ind: number;
  boq_code_desc: string;
  get_data: any;
}

interface IBoqCodeDetail {
  quantity: number;
  amount: number;
}

export interface IBoqCodeDetails {
  [boqNumber: string]: IBoqCodeDetail;
}

export interface IBoqCodeGroup {
  actualAmt: number;
  boqCodeDesc: string;
  boqCodeName: string;
  boqSectionName: string;
  metricInd: number;
  boqs: IBoqCodeDetails;
  data: any;
}

function getUnit(metricInd: number): string | null {
  if (metricInd) {
    return UnitTable.filter(x => x.unit_id === metricInd)[0].unit_name;
  } else {
    return null;
  }
}

export class BoqCodeGroup implements IBoqCodeGroup {
  actualAmt: number;
  boqCodeDesc: string;
  boqCodeName: string;
  boqSectionName: string;
  boqs: IBoqCodeDetails;
  metricInd: number;  
  shouldDisplayBoqSectionName = true;
  data: any;

  public get boq_code_name(): string {
    return this.boqCodeName;
  }

  public get boq_code_desc(): string {
    return this.boqCodeDesc;
  }

  public get get_data(): any {
    return this.get_data;
  }

  public getBoqToDisplay(selectedBoqs: number[]): (IBoqCodeDetail & { boqNum: number })[] {
    return selectedBoqs.map(boqNum => ({...this.boqs[boqNum], boqNum}));
  }

  constructor(data: IBoqCodeGroup) {
    this.actualAmt = data.actualAmt;
    this.boqCodeDesc = data.boqCodeDesc;
    this.boqCodeName = data.boqCodeName;
    this.boqSectionName = data.boqSectionName;
    this.boqs = data.boqs;
    this.metricInd = data.metricInd;
    this.data = data.data;
  }

  public get unit(): string {
    return getUnit(this.metricInd);
  }

  get boq_section_name() {
    return this.boqSectionName;
  }
}

export type ICompareBoqResultV3Raw = IBoqCodeToCompare & {
  [k: string]: string;
};

function textToNumber(s: string): number {
  s = /^\$/[Symbol.replace](s, '');
  s = /,/g[Symbol.replace](s, '');
  return parseInt(s, 10);
}

export class BoqCodeToCompare implements IBoqCodeToCompare, IOptionPageRow {
  private readonly data: ICompareBoqResultV3Raw;

  public get baseDesignRate(): number {
    return this.actual_amt;
  }

  public get optionRate(): number {
    return this.actual_amt;
  }

  constructor(data: ICompareBoqResultV3Raw) {
    this.data = data;
  }

  public get actual_amt() {
    return this.data.actual_amt;
  }

  public get boq_code_cd() {
    return this.data.boq_code_cd;
  }

  public get boq_code_desc() {
    return this.data.boq_code_desc;
  }

  public get boq_section_name() {
    return this.data.boq_section_name;
  }

  public get get_data() {
    return this.data;
  }


  // tslint:enable:variable-name

  public get metric_ind() {
    return this.data.metric_ind;
  }

  public get unit(): string | null {
    return getUnit(this.metric_ind);
  }

  public get baseDesignAmount(): number {
    const baseDesignBoqName = this.baseDesignBoqName;
    if (baseDesignBoqName == null) {
      return 0;
    }
    return this.getAmountFromBoqName(baseDesignBoqName);
  }

  public get optionAmount(): number {
    const optionBoqName = this.optionBoqName;
    if (optionBoqName == null) {
      return 0;
    }
    return this.getAmountFromBoqName(optionBoqName);
  }

  public get baseDesignQuantity(): number {
    const baseDesignBoqName = this.baseDesignBoqName;
    if (baseDesignBoqName == null) {
      return 0;
    }
    return this.getQuantityFromBoqName(baseDesignBoqName);
  }

  public get optionQuantity(): number {
    const optionBoqName = this.optionBoqName;
    if (optionBoqName == null) {
      return 0;
    }
    return this.getQuantityFromBoqName(optionBoqName);
  }

  private get baseDesignBoqName(): string | null {
    //e.g. Quantity_CALC-2_Base Design Infrastructure
    return this.findColMatchingRegex(BASE_DESIGN_QUANTITY_FORMAT);
  }

  private get optionBoqName(): string | null {
    const baseDesignBoqName = this.baseDesignBoqName;
    if (!baseDesignBoqName) {
      return null;
    }
    //e.g. Quantity_CALC-2_2048
    /*Why exclude key match baseDesignBoqName? Because baseDesignBoqName also matches OPTION_QUANTITY_FORMAT*/
    return this.findColMatchingRegex(
      OPTION_QUANTITY_FORMAT,
      col => !new RegExp(baseDesignBoqName).test(col));
  }

  public getQuantityFromBoqNum(boqNum: number | string, boqs: { [boqNum: string]: BoqToCompare }): number {
    return this.getNumber(boqNum, boqs, 'Quantity');
  }

  public getAmountFromBoqNum(boqNum: number | string, boqs: { [boqNum: string]: BoqToCompare }): number {
    return this.getNumber(boqNum, boqs, 'Amount');
  }

  private getQuantityFromBoqName(boqName: string): number {
    return this.getNumberFromBoqName(boqName, 'Quantity');
  }

  private getAmountFromBoqName(boqName: string): number {
    return this.getNumberFromBoqName(boqName, 'Amount');
  }

  private findColMatchingRegex(format: RegExp, matchExtraConditions?: (col: string) => boolean): string | null {
    const col = Object.keys(this.data).find(key => {
      if (matchExtraConditions) {
        return format.test(key) && matchExtraConditions(key);
      }
      return format.test(key);
    });
    if (!col) {
      return null;
    }
    const result = format.exec(col);
    return result[1];
  }

  private getNumber(boqNum: number | string, boqs: { [boqNum: string]: BoqToCompare }, prefix: string): number {
    const boqName = boqs[boqNum].boqName;
    return this.getNumberFromBoqName(boqName, prefix);
  }

  private getNumberFromBoqName(boqName: string, prefix: string): number {
    const s = this.data[`${prefix}_${boqName}`];
    if (!_.isNil(s)) {
      return textToNumber(s);
    }
    return 0;
  }

  public get currentDesignQuantity(): number {
    return this.baseDesignQuantity;
  }

  public get description(): string {
    return this.boq_code_desc;
  }

  public get payItem(): string {
    return this.boq_code_cd;
  }

  public get rowType(): 'section-header' | 'row' | '' {
    return 'row';
  }
}

export class CompareResult
{
  dataSet: BoqCodeToCompare[];
  preferredInf: string;
  baseInf: string;
}
